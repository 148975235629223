<template>
  <div v-show="show"
       id="graph-window"
       class="flex shrink-0 grow flex-wrap p-2 overflow-y-auto border-2 
              border-neutral-100 hover:shadow-xl"
       :class="{'basis-1/2': !full_screen, 
                'min-h-[50%]': !full_screen,
                'max-h-[50%]': !full_screen,
                'basis-1': full_screen,
                'h-full': full_screen}">
    <div class="relative top-0 h-6 w-full z-50"
         style="font-variation-settings:  'wght' 700, 'GRAD' 0, 'opsz' 48;"
         id="table-expander">
      <span class="font-symbol material-symbols-outlined w-6 float-right
                  bg-white"
            v-show="!full_screen"
            @click="full_screen = true">fullscreen</span> 
      <span class="font-symbol material-symbols-outlined w-6 float-right"
            v-show="full_screen"
            @click="full_screen = false">fullscreen_exit</span> 
    </div>
    <div id="chart" v-show="show_graph"
         class="w-full pr-4 h-full relative -mt-2 -mb-20">
        <apexchart type="line" class="w-full h-full" 
                   :options="chartOptions" 
                   :series="series"></apexchart>
      </div>
  </div>
</template>

<script>
  import VueApexCharts from "vue3-apexcharts";

  export default {
                
    name: 'GraphComponent',

    components: {
      'apexchart': VueApexCharts
    },

    props:[
      'lulc',
      'lulc_key',
      'lulc_version',
      'selection',
      'visible'
    ],
            
    emits: [
      'full_screen',
    ],

    watch:{
      full_screen: function(){
        this.$emit('full_screen', this.full_screen, this.$.type.name);
      },

      selection: {
        deep: true,
        handler(){
          if(!this.selection.id){
            this.clearChart();
            return;
          }
          this.show_graph = true;
          const arr = this.lulc.filter(({ type }) => type === 
                      this.selection.geography.substring(0, 1));

          this.stats = arr.filter(({ id }) => id === 
                                                   parseInt(this.selection.id));
          this.getType1OverTime();
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      },
    },

    data(){
      return{
        chartOptions: {},
        full_screen: false,
        height: 0,
        series: [],
        show: true,
        show_graph: false,
        stats: {}
      }
    },

    mounted(){
      this.addResizer();
    },

    methods:{

      addResizer(){
        const observer = new ResizeObserver(()=> {
          this.height = document.getElementById('graph-window').offsetHeight/1.5;
          if(this.selection){
            this.getType1OverTime();
          }
        });
        observer.observe(document.getElementById('graph-window'));
      },

      clearChart(){
        this.series = [];
        this.show_graph = false;
      },

      getType1OverTime(){
        const data = this.stats[0];
        let series = [];
        let options = {};

        series.push({name: 'Agriculture',
         data: [data.f8, data.f9, data.f10, data.f11, data.f12, data.f13, 
                data.f14]});
        series.push({name: 'Forest',
         data: [data.f1, data.f2, data.f3, data.f4, data.f5, data.f6,
                data.f7]});
        series.push({name: 'Barren',
         data: [data.f22, data.f23, data.f24, data.f25, data.f26, data.f27, 
                data.f28]});
        series.push({name: 'Water',
         data: [data.f15, data.f16, data.f17, data.f18, data.f19, data.f20, 
                data.f21]});
        series.push({name: 'Wetland',
         data: [data.f29, data.f30, data.f31, data.f32, data.f33, data.f34, 
                data.f35]});
        series.push({name: 'Urban',
         data: [data.f36, data.f37, data.f38, data.f39, data.f40, data.f41, 
                data.f42]});

        options['chart'] = {
          height: this.height,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        options['dataLabels'] = {
          enabled: false
        },

        options['colors'] = ['#a6ffa5', '#018200', '#fffbc3', 
                                       '#0000c8', '#00d1dc', '#ffa5a5'],
                                       
        options['stroke'] = {
          width: 4,
          curve: 'straight',
          dashArray: 0
        },

        options['title'] =  {
          text: data.label,
          align: 'left'
        },
        
        options['subtitle'] =  {
          text: 'Land Cover Over Time',
          align: 'left'
        },
        
        options['markers']= {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        options['xaxis'] = {
          categories: [1986, 1995, 2002, 2007, 2012, 2015, 2020],
          title: {
              text: 'Year'
          }
        },

        options['yaxis'] = {
          labels: {
            formatter: function(val) {
              return Math.round(val).toLocaleString('en-US');
              }
            },
            title: {
                text: 'Acres'
            }
        },

        options['markers'] = {
          size: 5,
          colors: ['#a6ffa5', '#018200', '#fffbc3', 
                                       '#0000c8', '#00d1dc', '#ffa5a5'],
          strokeColors:['#a6ffa5', '#018200', '#fffbc3', 
                                       '#0000c8', '#00d1dc', '#ffa5a5'],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: 8,
            sizeOffset: 3
          }
        },

        options['tooltip'] = {

          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val + " acres"
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            }
          ]
        },  
        options['grid'] = {
          borderColor: '#f1f1f1',
          xaxis: {
            'lines': {show: true}
          }
        },
        
        this.series = series;
        this.chartOptions = options;
      }
    }
  }

</script>