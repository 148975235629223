import { createRouter, createWebHashHistory } from 'vue-router'
import HomeComponent from '../components/HomeComponent.vue'

const routes = [
  { path: '/', name: 'Home', component: HomeComponent, alias: '/home' },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
