<template><div></div></template>

<script>

  export default {

      name: "OverlayComponent",

      data(){
          return{
              data_error: '',
              current_overlay: null,
              default_overlay: 'lulc_1',
              level: 1,
              overlay: {
                'source': {
                  'name': 'data_vector',
                  'type': 'vector',
                  'tiles': [],
                  'minzoom': 6,
                  'maxzoom': 13,
                },
                'layer': {
                    'id': 'data_vector',
                    'type': 'fill',
                    'source': 'data_vector',
                    'source_layer': 'default'
                  }
              },
              showing: false,
              type: 'lulc'
          }
      },

      props: {
        'popup': {
          type: Object,
          default: null 
        },
        'map': {
          type: Object,
          default: null 
        },
        'selected_overlay': {
          type: String,
          default: 'lulc_1' 
        },
        'selection': {
          type: Object,
          default: null 
        },
        'lulc_version': {
          type: String,
          default: '20' 
        },
        'lulc_codes': {
          type: Object,
          default: null 
        }
      },

      watch:{

       'lulc_version': function(){
          this.updateLayer();
        },

       'selection': {
          deep: true,
          handler() {

            if(!this.selection.id){
              this.removeLayer();
              return;
            }

            this.updateLayer();
          }
        },

        'selected_overlay': {
          handler() {
            if(!this.selected_overlay){
              this.removeLayer();
              this.current_overlay = this.default_overlay;
            }else{
              this.current_overlay = this.selected_overlay;
              this.updateLayer();
            }

          }
        },

        style_change: function(){
          this.addSource();
          if(this.showing){
            this.addLayer();
          }
        }
      },

      mounted(){
        this.current_overlay = this.default_overlay;
      },

      methods:{

        addInteractions(){
          this.removeInteractions();
          this.map.on('mouseleave', this.overlay.layer.id, 
                                    (e) => this.removeHoverHandler(e));
          this.map.on('mousemove', this.overlay.layer.id, 
                                   (e) => this.addHoverHandler(e));
        },

        addLayer(){
          
          this.removeLayer();

          this.map.addLayer({
            'id': this.overlay.layer.id,
            'type': this.overlay.layer.type,
            'source': this.overlay.source.name, 
            'source-layer': this.overlay.layer.source_layer,
            'paint':  {'fill-outline-color': 'transparent',
                       'fill-opacity': 0
                      }
          });
          this.addInteractions();
        },

        addHoverHandler(e){

          const coordinates = e.lngLat;
          let item_code = '';
          let type = e.features[0].properties.t;
          let text = '';
          let arr = [];

          this.map.getCanvas().style.cursor = 'pointer';        
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          switch(this.current_overlay){
            case 'lulc_1':
              arr = this.lulc_codes.filter(({ code }) => code === type);
              text = arr[0].label
              break;
            case 'lulc_2':
              arr = this.lulc_codes.filter(({ code }) => code === type);
              text = '<div class="text-center">' + arr[0].label;
              item_code = e.features[0].properties.c;
              arr = this.lulc_codes.filter(({ code }) => code === 
                                                            String(item_code));
              text +=  '<br>(' + String(item_code) + ': ' + 
                        arr[0].label + ')</div>';
              break;
          }
          
          this.popup.setLngLat(coordinates)
          .setHTML(text).addTo(this.map);
        },

        addSource(){
            if(this.map.getSource(this.overlay.source.name)){
              this.map.removeSource(this.overlay.source.name);
            }
          
            this.map.addSource(this.overlay.source.name, {
                'type': this.overlay.source.type,
                'tiles': this.overlay.source.tiles,
                'minzoom': this.overlay.source.minzoom,
                'maxzoom': this.overlay.source.maxzoom,
            });
        },

        makeTileUrl(){
          const base = 'https://d20jbwevdggj78.cloudfront.net/geolimited/';
                      
          let url = base 
                    + this.selection['geography']
                    + '/' + this.type
                    + '-l'
                    + this.level
                    + '/' + this.selection['id'] 
                    + '/' + this.lulc_version 
                    + '/{z}/{x}/{y}.pbf';

          return url;

        },

        removeHoverHandler(){ 
          this.map.getCanvas().style.cursor = '';
          if(this.popup){
            this.popup.remove();
          }
        },

        removeLayer(){      
          if(this.map.getLayer(this.overlay.layer.id)){
            this.removeInteractions();
            this.map.setPaintProperty(this.overlay.layer.id, 'fill-opacity', 0);
            setTimeout(() => {
              this.map.removeLayer(this.overlay.layer.id);
            }, 300);
          }
        },

        removeInteractions(){
          this.map.off('click', this.overlay.layer.id, this.handleLayerClick);
        },

        setDefaultPaintProperty(){
            if(this.current_overlay == 'lulc_1'){
              this.map.setPaintProperty('data_vector', 'fill-color', 
                      ['match', ['get', 't'],
                        'A', "#a6ffa5",
                        'B', "#ffdd00",
                        'F', "#018200",
                        'U', "#ffa5a5",
                        'W', "#0000c8",
                        'S', "#00d1dc",
                        'transparent'
                      ],
                    );
            }else if(this.current_overlay == 'lulc_2'){
              this.map.setPaintProperty('data_vector', 'fill-color', 
              [
                          'case',
                            ['<', ['get', 'c'], '12'],
                            '#FFB8B8',
                            ['<', ['get', 'c'], '13'],
                            '#FF6C6C',
                            ['<', ['get', 'c'], '14'],
                            '#FF7F7F',
                            ['<', ['get', 'c'], '15'],
                            '#FF9292',
                            ['<', ['get', 'c'], '16'],
                            '#FFA5A5',
                            ['<', ['get', 'c'], '17'],
                            '#FF5959',
                            ['<', ['get', 'c'], '18'],
                            '#FFCBCB',
                            ['<', ['get', 'c'], '19'],
                            '#5AFF59',
                            ['<', ['get', 'c'], '22'],
                            '#67FF65',
                            ['<', ['get', 'c'], '23'],
                            '#8cd98b',
                            ['<', ['get', 'c'], '24'],
                            '#74FF72',
                            ['<', ['get', 'c'], '41'],
                            '#016800',
                            ['<', ['get', 'c'], '42'],
                            '#014F00',
                            ['<', ['get', 'c'], '43'],
                            '#016800',
                            ['<', ['get', 'c'], '44'],
                            '#018200',
                            ['<', ['get', 'c'], '45'],
                            '#01A800',
                            ['<', ['get', 'c'], '51'],
                            '#0808d4',
                            ['<', ['get', 'c'], '52'],
                            '#0000AF',
                            ['<', ['get', 'c'], '53'],
                            '#0707C9',
                            ['<', ['get', 'c'], '54'],
                            '#0E0EE4',
                            ['<', ['get', 'c'], '61'],
                            '#00a1a9',
                            ['<', ['get', 'c'], '62'],
                            '#00d1dc',
                            ['<', ['get', 'c'], '65'],
                            '#10f3ff',
                            ['<', ['get', 'c'], '71'],
                            '#ccb100',
                            ['<', ['get', 'c'], '72'],
                            '#e6c700',
                            ['<', ['get', 'c'], '73'],
                            '#ffdd00',
                            ['<', ['get', 'c'], '74'],
                            '#ffe01a',
                            ['<', ['get', 'c'], '75'],
                            '#ffe433',
                            ['<', ['get', 'c'], '76'],
                            '#ffe74d',
                            ['<', ['get', 'c'], '80'],
                            '#fae873',
                            ['<', ['get', 'c'], '81'],
                            '#008890',
                            'transparent'
                        ],
                    );
            }
        },
        
        setSourceLayer(){
          const source = 'tiles.' + this.type + '_' + this.selection.geography;
          this.overlay.layer.source_layer = source;

          return source;
        },

        updateLayer(){ 
          const parts = this.current_overlay.split('_');
          this.type = parts[0];
          this.level = parts[1]
          this.overlay.source.tiles.pop();
          this.overlay.source.tiles.push(this.makeTileUrl());
          this.setSourceLayer();
          if(this.map.getLayer(this.overlay.layer.id)){
            this.map.setPaintProperty(this.overlay.layer.id,
            'fill-opacity-transition', { duration: 310 } );
            this.map.setPaintProperty(this.overlay.layer.id, 'fill-opacity', 0);
          }
          this.removeLayer();
          this.setSourceLayer();

          setTimeout(() => {
            this.addSource();
            this.addLayer();          
            this.setDefaultPaintProperty();
            this.map.setPaintProperty(this.overlay.layer.id,
            'fill-opacity-transition', { duration: 310 } );
            this.map.setPaintProperty(this.overlay.layer.id,'fill-opacity', 1);
            }, 300);
         

        }
      }
  }

</script>
