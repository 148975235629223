<template>
  
  <div class="flex bg-white w-full md:w-2/5 "
        id="header">
    <!-- <div class="h-20 w-full bg-gradient-to-r from-hcap_green from-30% via-hcap_blue via-60% to-hcap_blue/[.95] md:to-hcap_blue/[.05] to-95%"> -->
    <div class="h-20 w-full">
      <div class="flex flex-nowrap items-center">
        <div class="bg-white flex flex-nowrap basis-24 md:basis-36 border-b border-hcap_blue/40">
          <img class="w-22 h-20 pb-1 mx-auto"  :src="landscape_logo_svg">
        </div>
        <div class="flex text-left text-xl md:text-2xl lg:text-4xl ml-8 font-bold font-roboto text-hcap_logo_blue">
          <span>Land Use/Cover/span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import grass_svg from "../assets/img/grass.svg" 
  import landscape_logo_svg from "../assets/img/landscape_logo.svg" 
  import landscape_svg from "../assets/img/landscape.svg" 

  export default {
    name: 'HomeComponent',

    data() {
      return {
        grass_svg,
        landscape_logo_svg,
        landscape_svg,
      }
    },

    props: {
      msg: String
    }
  }

</script>
