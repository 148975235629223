<template>
  <div class="relative md:absolute flex flex-wrap mt-0 md:-mt-20 right-0 top-0 h-2/3 md:h-full w-full md:w-3/5 bg-white place-content-start overflow-hidden"
        id="content-container">
    <map-component
        v-bind:lulc_codes="lulc_codes"
        v-bind:lulc_version = "lulc_version"
        v-bind:parent_height="element_height"
        v-bind:resize_map="resize_map"
        v-bind:selected_overlay="selected_overlay"
        v-bind:selection="selection"
        v-bind:visible="visible"
        @full_screen="updateContentDisplay"
        @map="updateMap"
        @map_loaded="updateMapLoaded"
        @popup="updatePopup"/>
    <graph-component
        v-bind:lulc = "lulc"
        v-bind:lulc_key = "lulc_key"
        v-bind:lulc_version = "lulc_version"
        v-bind:parent_height="element_height"
        v-bind:selection="selection"
        v-bind:visible="visible"
        @full_screen="updateContentDisplay"/>
    <miscellaneous-component
        v-bind:lulc = "lulc"
        v-bind:lulc_key = "lulc_key"
        v-bind:lulc_version = "lulc_version"
        v-bind:parent_height="element_height"
        v-bind:selection="selection"
        v-bind:visible="visible"
        @full_screen="updateContentDisplay"/>
    <data-component
        v-bind:lulc = "lulc"
        v-bind:lulc_key = "lulc_key"
        v-bind:lulc_version = "lulc_version"
        v-bind:parent_height="element_height"
        v-bind:selection="selection"
        v-bind:visible="visible"
        @full_screen="updateContentDisplay"/>
  </div>
</template>

<script>

  import GraphComponent from "./GraphComponent.vue" 
  import MapComponent from "./MapComponent.vue" 
  import MiscellaneousComponent from "./MiscellaneousComponent.vue" 
  import DataComponent from "./DataComponent.vue" 

  export default {
    name: 'ContentComponent',

    components:{
      "graph-component": GraphComponent,
      "map-component": MapComponent,
      "miscellaneous-component": MiscellaneousComponent,
      "data-component": DataComponent
    },

    props:[
      'lulc',
      'lulc_codes',
      'lulc_version',
      'lulc_key',
      'selected_overlay',
      'selection'
    ],
        
    emits: [
      'map',
      'map_loaded',
      'popup'
    ],

    data() {
      return {
        map: null,
        map_loaded: false,
        resize_map: false,
        visible:{}
      }
    },

    computed: {
      element_height: function () {
        const header_height = document.getElementById('header')?document.getElementById('header').offsetHeight:0;
        const client_height = document.documentElement.clientHeight;
        
        return client_height - header_height;
      },

      element_height_class: function(){
        return  `h-[${this.element_height}]`;
      }
    },

    mounted(){
      Object.values(this.$.components).forEach(
        (value)=>{
          if(value.name){
            this.visible[value.name] = true
          }else{
            this.visible['DataComponent'] = true
          }
        }
      ); 
    },

    methods:{
      updateContentDisplay(state=false, component='DataComponent'){
        Object.values(this.$.components).forEach(
          (value)=>{
            if(state){
              if(value.name == component){
                this.visible[value.name] = true;
              }else{
                this.visible[value.name] = false;
              }
            }else{
              this.visible[value.name] = true;
            }
          }
        );
        this.resize_map = !this.resize_map;
      },

      updateMap(map){
        this.map = map;
        this.$emit('map', this.map)
      },

      updateMapLoaded(status){
        this.map_loaded = status;
        this.$emit('map_loaded', this.map_loaded)
      },

      updatePopup(popup){
        this.$emit('popup', popup);
      }
    }
  }

</script>