<template>
  <div v-show="show"
       class="flex shrink-0 grow flex-wrap p-2 overflow-y-auto border-2 
             border-neutral-100 hover:shadow--xl content-start"
       :class="{'basis-1/2': !full_screen, 
                'min-h-[50%]': !full_screen,
                'max-h-[50%]': !full_screen,
                'basis-1': full_screen,
                'h-full': full_screen}">

    <div class="grid grid-cols-2 w-full">
      
      <div class="align-items-center col-span-1 grid grid-cols-6
                  justify-content-between justify-start gap-2"
            @click="show_link=!show_link"
            :class="show_menu ? 'opacity-1' : 'opacity-0'">
        <span class="apexcharts-menu-icon col-span-1" title="Menu"
              v-show="records.data">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z">
                </path>
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z">
                </path>
          </svg>
        </span>
        <span class="py-0.5 px-7 col-span-5 h-6 cursor-pointer text-xs  cur text-center border border-neutral-200" 
              :class="[ records.data? 'cursor-pointer':'cursor-default',
                        show_link ? 'opacity-1' : 'opacity-0']"
              title="Download CSV" 
              id="download-csv">
        </span>
      </div>

      <div class="h-6 col-span-1"
          style="font-variation-settings:  'wght' 700, 'GRAD' 0, 'opsz' 48;"
          id="table-expander">
        <span class="font-symbol material-symbols-outlined w-6 float-right 
                    bg-white"
              v-show="!full_screen"   
              @click="full_screen = true">fullscreen</span> 
        <span class="font-symbol material-symbols-outlined w-6 float-right"
              v-show="full_screen"
              @click="full_screen = false">fullscreen_exit</span>
      </div>
      <div class="card col-span-2" v-show="records.data">
        <DataTable :value="records.data" tableStyle="min-width: 50rem"
        stripedRows showGridlines selectionMode="single" >

          <template #header>
            <div class="flex flex-none align-items-center justify-content-between basis-1/2
                        gap-2 border-b border-hcap_logo_green py-1">  
              <span class="w-1/2 float-left basis-1/3">
                <p class="text-base text-900 font-bold whitespace-nowrap"
                  >{{records.title}}</p>
                <p class="text-sm font-normal text-800 whitespace-nowrap pb-2"
                >Land Cover Over Time</p>
              </span>
            </div>
          </template>
          <Column v-for="col of columns" 
                  :key="col.field" 
                  :field="col.field" 
                  :header="col.header"
                  sortable>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

  import Column from 'primevue/column';
  import DataTable from 'primevue/datatable';
  import { json2csv } from 'json-2-csv';

  export default {

    name: 'DataComponent',

    components: {
      Column,
      DataTable
    },
    
    props: [
      'lulc',
      'lulc_key',
      'lulc_version',
      'selection',
      'visible'
    ],
    
    emits: [
      'full_screen',
    ],

    data() {
      return {
        columns: [
            { field: 'year', header: 'Year' },
            { field: 'forest', header: 'Forest' },
            { field: 'agriculture', header: 'Agriculture' },
            { field: 'wetlands', header: 'Wetlands' },
            { field: 'urban', header: 'Urban' },
            { field: 'water', header: 'Water' },
            { field: 'barren', header: 'Barren' }
        ],
        full_screen: false,
        height: 0,
        show: true,
        show_link: false,
        show_menu: false,
        title: ''
      }
    },
    watch:{
      full_screen: function(){
        this.$emit('full_screen', this.full_screen, this.$.type.name);
      },

      selection: {
        deep: true,
        handler(){
          if(this.selection.id){
            this.createCsvLink();
            this.show_menu  = true;
          }
        }

      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      },
    },
    
    computed: {

      records: function(){
          if(!this.selection){
            return [];
          }
          if(!this.selection.id){ 
            return []; 
          }
          const arr = this.lulc.filter(({ type }) => type === 
                      this.selection.geography.substring(0, 1));

          const rec = arr.filter(({id}) => id === parseInt(this.selection.id))[0];
          const title = rec.label;

          const rows = [{
            year: 1986,
            forest: Math.round(rec.f1).toLocaleString(),
            agriculture: Math.round(rec.f8).toLocaleString(),
            wetlands: Math.round(rec.f29).toLocaleString(),
            urban: Math.round(rec.f36).toLocaleString(),
            water: Math.round(rec.f15).toLocaleString(),
            barren: Math.round(rec.f22).toLocaleString()
          },{
            year: 1995,
            forest: Math.round(rec.f2).toLocaleString(),
            agriculture: Math.round(rec.f9).toLocaleString(),
            wetlands: Math.round(rec.f30).toLocaleString(),
            urban: Math.round(rec.f37).toLocaleString(),
            water: Math.round(rec.f16).toLocaleString(),
            barren: Math.round(rec.f23).toLocaleString()
          },{
            year: 2002,
            forest: Math.round(rec.f3).toLocaleString(),
            agriculture: Math.round(rec.f10).toLocaleString(),
            wetlands: Math.round(rec.f31).toLocaleString(),
            urban: Math.round(rec.f38).toLocaleString(),
            water: Math.round(rec.f17).toLocaleString(),
            barren: Math.round(rec.f24).toLocaleString()
          },{
            year: 2007,
            forest: Math.round(rec.f4).toLocaleString(),
            agriculture: Math.round(rec.f11).toLocaleString(),
            wetlands: Math.round(rec.f32).toLocaleString(),
            urban: Math.round(rec.f39).toLocaleString(),
            water: Math.round(rec.f18).toLocaleString(),
            barren: Math.round(rec.f25).toLocaleString()
          },{
            year: 2012,
            forest: Math.round(rec.f5).toLocaleString(),
            agriculture: Math.round(rec.f12).toLocaleString(),
            wetlands: Math.round(rec.f33).toLocaleString(),
            urban: Math.round(rec.f40).toLocaleString(),
            water: Math.round(rec.f19).toLocaleString(),
            barren: Math.round(rec.f26).toLocaleString()
          },{
            year: 2015,
            forest: Math.round(rec.f6).toLocaleString(),
            agriculture: Math.round(rec.f13).toLocaleString(),
            wetlands: Math.round(rec.f34).toLocaleString(),
            urban: Math.round(rec.f41).toLocaleString(),
            water: Math.round(rec.f20).toLocaleString(),
            barren: Math.round(rec.f27).toLocaleString()
          },{
            year: 2020,
            forest: Math.round(rec.f7).toLocaleString(),
            agriculture: Math.round(rec.f14).toLocaleString(),
            wetlands: Math.round(rec.f35).toLocaleString(),
            urban: Math.round(rec.f42).toLocaleString(),
            water: Math.round(rec.f21).toLocaleString(),
            barren: Math.round(rec.f28).toLocaleString()
          }];

          return {"data": rows, 'title': title};
    }
  },

  methods:{
    createCsvLink(){
      const csvContent = json2csv(this.records.data);

      const blob = new Blob([csvContent], 
                                          { type: 'text/csv;charset=utf-8,' });
      const url = URL.createObjectURL(blob);

      let link = document.createElement('a');
      link.classList.add('w-32');
      link.classList.add('border-b');
      link.classList.add('border-neutral-200');
      link.setAttribute('href', url);
      link.setAttribute('id', 'csv-link');
      link.setAttribute('download', this.records.title + '(Land Use).csv');
      document.getElementById('download-csv').append(link);
      document.getElementById('csv-link').innerHTML = 'Download CSV';
    }
  }
}
</script>