<template>
  <div class="flex flex-col w-full h-1/3 md:w-2/5 md:h-full pt-4 mr-6
              place-content-start hover:shadow-xl group">
    <boundaries-component
      v-bind:lulc = "lulc"
      v-bind:lulc_key = "lulc_key"
      v-bind:map = "map"
      v-bind:map_loaded = "map_loaded"
      v-bind:popup = "popup"
      @selection="changeSelection"/>
    <overlay-menu
      v-bind:lulc_version = "lulc_version"
      @selected_overlay="changeOverlay"/>
    <version-menu
     @lulc_version="setLulcVersion"/>
  </div>
</template>
<script>

  import BoundariesComponent from "./BoundariesComponent";
  import OverlayMenu from "./OverlayMenu";
  import VersionMenu from "./VersionMenu";

  export default {

    components:{
      'boundaries-component': BoundariesComponent,
      'overlay-menu': OverlayMenu,
      'version-menu': VersionMenu
    },

    emits:[
      'lulc_version',
      'selected_overlay',
      'selection'
    ],

    props:[
      'lulc',
      'lulc_key',
      'map',
      'map_loaded',
      'popup'
    ],

    data(){
      return{
        'lulc_version': '20',
        selection: null,
      }
    },

    methods:{
      changeSelection(selection){
        this.selection = selection;
        this.$emit('selection', this.selection)
      },
      setLulcVersion(version){
        this.lulc_version = version;
        this.$emit('lulc_version', this.lulc_version)
      },
      changeOverlay(overlay){
        this.$emit('selected_overlay', overlay);
      }
    }

  }

</script>