import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/tailwind.css'
import { VueResponsiveness, Presets } from "vue-responsiveness";
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-green/theme.css'

// import { createAuth0 } from '@auth0/auth0-vue';

const app = createApp(App)
    .use(router)
    .use(VueResponsiveness, Presets.Tailwind_CSS)
    .use(PrimeVue, {});

// app.use(
//   createAuth0({
//     domain: "dev-hsdi0eud.us.auth0.com",
//     clientId: "IKEOaaStBvtmcfmfoC4FEx7Q6bqRqRxE",
//     authorizationParams: {
//       redirect_uri: 'https://conservationblueprint-nj.com/#ga'
//     }
//   })
// );

app.mount('#app');
