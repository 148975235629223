<template>

  <home-component/>

</template>

<script>

  import HomeComponent from './components/HomeComponent.vue'

  export default {
    
    name: 'App',
    
    components: {
      "home-component": HomeComponent,
    }
  }
</script>

